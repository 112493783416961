import React from "react";
import "./projects.css";
import classNames from "classnames";
import useSticky from "../useSticky";
import Slider from "../slider/slider";
import Footer from "../footer/footer";
import Header from "../header/header";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

// Array of numbers representing portfolio items
const portfolioNumbers = [1, 2, 3, 4, 5, 6, 7]; // Even numbers can represent videos
const video = [1, 2, 3];
const Projects = () => {
  const { sticky, stickyRef } = useSticky();

  return (
    <>
      <nav ref={stickyRef} className={classNames("nav", { sticky })}>
        <Header />
      </nav>
      <div
        style={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : "0px",
        }}
      />
      <div style={{ height: "100vh" }}>
        <Slider bg={"./image/project.png"} />
      </div>

      <section className="portfolio-section" style={{ marginTop: 200 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 class="wow fadeInUp reveal active">
            WEB DESIGN & <span class="tx-primary">APP DESIGN</span>{" "}
          </h1>
        </div>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={3}>
            {portfolioNumbers.map((num) => (
              <Grid item xs={12} sm={6} md={4} key={num}>
                <Item>
                  <img
                    src={`./image/project/${num}.jpg`}
                    alt={`Portfolio ${num}`}
                    className="portfolio-image"
                  />
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 class="wow fadeInUp reveal active">
            LOGO<span class="tx-primary"> DESIGN</span>{" "}
          </h1>
        </div>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={3}>
            {portfolioNumbers.map((num) => (
              <Grid item xs={12} sm={6} md={4} key={num}>
                <Item>
                  <img
                    src={`./image/logo/${num}.jpg`}
                    alt={`Portfolio ${num}`}
                    className="portfolio-image"
                  />
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 class="wow fadeInUp reveal active">
            Video<span class="tx-primary"> graphy</span>{" "}
          </h1>
        </div>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={3}>
            {video.map((num) => (
              <Grid item xs={12} sm={6} md={4} key={num}>
                <Item>
                  <video
                    controls
                    className="portfolio-video"
                    style={{ width: "100%", height: 200 }}
                  >
                    <source src={`./image/video/${num}.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </section>
    </>
  );
};

export default Projects;
